import React from "react";

const ProgressBar = (props) => {
	const {width} = props;
	const {bgLine, frontLine, text} = props.progressBarCSS;
	return (
		<>
			<section>
				<div className= {` rounded-full flex justify-start items-center ${bgLine}`}>
					<div style={{width: `${width}%`}} className={` ${frontLine} rounded-full `}></div>
					{/* <div className= 'bg-dark2 h-3 rounded-full w-[67%]'></div> */}
				</div>
				<p className= {`text-center ${text}`}>{width}%</p>
			</section>
		</>
	);
};

export default ProgressBar;
