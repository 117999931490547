import React from "react";
import ProgressBar from "./ProgressBar";
import codeSVG from "../assets/codeCard.svg";
import { NavLink } from "react-router-dom";
import badge from "../assets/badge-png.png";



const LearnCard = (props) => {

	const { tagName, percentSolved, UserID, totalQuestions, solvedQuestions } = props;

	// <-------- Storting clicked Tag in Store ---------->
	const saveTag = (tag, percent) => {
		const TagProgress = {
			name: tag,
			percent: percent,
			total: totalQuestions,
			solved: solvedQuestions,
		};
		window.localStorage.setItem(
			"SELECTED_TAG_DATA",
			JSON.stringify(TagProgress)
		);
	};

	const CardProgressBarCSS = {
		bgLine: "bg-darkBlue px-0.5 h-3 md:h-4 md:px-1 dark:bg-blue",
		frontLine: "bg-blue h-2 md:h-2 dark:bg-darkBlue",
		text: "text-darkBlue  text-md font-semibold mt-0.5 dark:text-blue2",
	};
	return (
		<>
			{UserID ? (
				<NavLink
					to="/solve"
					onClick={() => {
						saveTag(tagName, percentSolved);
					}}
					key={tagName}
					className="bg-blue5 px-5 py-5 lg:py-8 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
				>
					<div className="bg-blue3 p-7 rounded-lg lg:p-[25%] dark:bg-blue">
						{
							percentSolved >= 100 ? (
								<img src={badge} alt="code SVG" />
							) : (
								<img src={codeSVG} alt="code SVG" />
							)
						}
					</div>
					<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
						{tagName}
					</p>
					<div className="w-[100%]">
						<ProgressBar progressBarCSS={CardProgressBarCSS} width={[percentSolved]} />
					</div>
				</NavLink>
			) : tagName === "C++ BASICS" || tagName === "C++ STL" ? (
				<NavLink
					to="/solve"
					onClick={() => {
						saveTag(tagName, 0);
					}}
					key={tagName}
					className="bg-blue5 px-5 py-5 lg:py-8 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer"
				>
					<div className="bg-blue3 p-7 rounded-lg lg:p-[25%] dark:bg-blue">
						<img src={codeSVG} alt="code SVG" />
					</div>
					<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
						{tagName}
					</p>
				</NavLink>
			) : (
				<NavLink
					to="/login"
					key={tagName}
					className="bg-blue5 px-5 py-5 lg:py-8 lg:px-8 rounded-xl flex flex-col items-center justify-center dark:bg-dark4 hover:scale-105 hover:drop-shadow-2xl ease-in-out duration-200 cursor-pointer relative"
				>
					<NavLink
						to="/login"
						className="absolute flex flex-col gap-5 items-center justify-center rounded-xl w-full h-full"
					>
						<div className="flex flex-col gap-5 items-center justify-center z-30">
							<i className="fa-solid fa-lock text-5xl text-dark"></i>
							<p className=" text-dark text-lg md:text-xl font-bold underline">
								Login to view
							</p>
						</div>
						<div className="bg-blue w-full h-full absolute flex flex-col gap-5 items-center justify-center rounded-xl opacity-80"></div>
					</NavLink>
					<div className="bg-blue3 p-7 rounded-lg lg:p-[25%] dark:bg-blue">
						<img src={badge} alt="card" />
					</div>
					<p className="text-md lg:text-lg text-center font-bold text-darkBlue mt-3 mb-3 lg:mt-6 dark:text-blue2">
						{tagName}
					</p>
				</NavLink>
			)}
		</>
	);
};

export default LearnCard;