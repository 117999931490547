import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import axios from "axios";
import { useDispatch } from "react-redux";
// import { getUserData } from "./redux/slices/userSlice";
import { fetchUser } from "./redux/slices/userSlice";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/Scroll";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
// import Phase1 from "./pages/Phases/Phase1";
// import Phase2 from "./pages/Phases/Phase2";
// import Phase3 from "./pages/Phases/Phase3";
import Learn from "./pages/Learn/Learn";
import Solve from "./pages/Learn/Solve";
import OA_Learn from "./pages/OA/OA_Learn";
import OA_Solve from "./pages/OA/OA_Solve";
import Profile from "./pages/Profile/Profile";
import Admin from "./pages/Admin/Admin";
import AddQuestions from "./pages/Admin/AddQuestions";
import AddOAQuestions from "./pages/Admin/AddOAQuestions";
import EditQuestion from "./pages/Admin/EditQuestion";
import EditOAQuestion from "./pages/Admin/EditOAQuestion";
import AddTag from "./pages/Admin/AddTag";
import AddCompany from "./pages/Admin/AddCompany";
import PauseBuy from "./pages/Admin/PauseBuy";
import Error from "./pages/Login/Error";
import Potd from "./pages/Potd/Potd";
import AddEditorial from "./pages/Admin/AddEditorial"
import AddContest from "./pages/Admin/AddContest";
import ShowProblems from "./components/ShowProblems";

import BlogPage from "./pages/Blog/Blog-Home/BlogPage"
import BlogDetails from "./pages/Blog/BlogDetails/BlogDetails"
import Dashboard from "./pages/Blog/Edit-page/Dashboard";
import Footer from "./components/Footer";




function App() {
	//DarkMode
	const [theme, setTheme] = useState(() => {
		// Retrieve theme from cookies, or default to "light"
		
		return Cookies.get("theme") || "dark";
	});
	useEffect(() => {
		if (theme === "dark") {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}

		Cookies.set("theme", theme, { expires: 365 }); // Set expiration to 1 year
	}, [theme]);

	const handleThemeSwitch = () => {
		setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
	};

	// Fetching current user from server
	const dispatch = useDispatch();
	dispatch(fetchUser());
	return (
		<Router>
			<ScrollToTop />
			<Navbar handleThemeSwitch={handleThemeSwitch} theme={theme} />
			<ToastContainer />
			<Routes>
				<Route path="*" element={<Home theme={theme} />} theme={theme} />
				<Route path="/" element={<Home theme={theme} />} />
				<Route path="/loginsucess" element={<Home theme={theme} />} />
				<Route path="/login" element={<Login theme={theme} />} />
				{/* // <Route path="/phase1" element={<Phase1 theme={theme} />} />
				// <Route path="/phase2" element={<Phase2 theme={theme} />} />
				// <Route path="/phase3" element={<Phase3 theme={theme} />} /> */}
				<Route path="/learn" element={<Learn theme={theme} />} />



				<Route path="/blog" element={<BlogPage />} />
				<Route path="/blog/:id" element={<BlogDetails />} />

				<Route path="/Blog-Admin" element={<Dashboard  />} />


				<Route path="/Upsolve/Platform" element={<AddEditorial theme={theme} />} />
				<Route path="/Solve" element={<Solve theme={theme} />} />
				<Route path="/OA_learn" element={<OA_Learn theme={theme} />} />
				<Route path="/OA_solve" element={<OA_Solve theme={theme} />} />
				<Route path="/Profile" element={<Profile theme={theme} />} />
				<Route path="/Admin" element={<Admin theme={theme} />} />
				<Route path="/admin/AddContest" element={<AddContest theme={theme} />} />
				<Route path="/AddEditorial" element={<AddEditorial theme={theme} />} />
				<Route path="/AddEditorial/:contestPlatform contest/:contestId" element={<ShowProblems theme={theme} />} />
			
				<Route path="/AddQuestions" element={<AddQuestions theme={theme} />} />
				<Route path="/AddOAQuestions" element={<AddOAQuestions theme={theme} />} />
				<Route path="/EditQuestion" element={<EditQuestion theme={theme} />} />
				<Route path="/EditOAQuestion" element={<EditOAQuestion theme={theme} />} />
				<Route path="/AddTag" element={<AddTag theme={theme} />} />
				<Route path="/AddCompany" element={<AddCompany theme={theme} />} />
				<Route path="/PauseBuy" element={<PauseBuy theme={theme} />} />
				<Route path="/problemoftheday" element={<Potd theme={theme} />} />
				<Route path="/Error" element={<Error theme={theme} />} />
			
			</Routes>
			
		</Router>
	);
}

export default App;
