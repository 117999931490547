import React, { useEffect, useState } from 'react';
import "./NavbarStyle.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import axios from "axios"
import { useDispatch } from "react-redux";
import { fetchProblems } from '../redux/slices/Potd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = (props) => {

  // const [potd,setPotd]= useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  const questions = useSelector((state) => state.Potd.data);
  // console.log(questions);


  useEffect(() => {
    dispatch(fetchProblems());
    // setTodaysproblem(questions[0]);
  }, [dispatch]);

  // console.log(potd);
  function go() {
    navigate("/problemoftheday", { state: questions })
  }
  const profile = JSON.parse(window.localStorage.getItem('PROFILE_PIC'));
  const openNav = () => {
    const hamburger = document.querySelector(".hamburger");
    const navLinks = document.querySelector(".nav-links");
    const links = document.querySelectorAll(".nav-links li");

    navLinks.classList.toggle("open");
    links.forEach((link) => {
      link.classList.toggle("fade");
    });

    //Hamburger Animation
    hamburger.classList.toggle("toggle");
  };

  const userData = useSelector((state) => state.user.user); //Fetching userData

  // <----------------- Scroll to top for Brand icon -------------------->

  function scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const notify = () => toast("Maintainance are going ON , You might face slow-loading");
  const notify2 = () => toast("you might face some bugs ");



  return (
    <>
      <nav className="bg-white text-black drop-shadow dark:bg-darkBlack dark:text-blue2">
        <div className="logo text-2xl">
          <NavLink to="/" onClick={scrollUp} className="font-bold text-blue">
            Senior
          </NavLink>
        </div>
        <div className="hamburger " onClick={openNav}>
          <div className="line1 bg-blue"></div>
          <div className="line2 bg-blue"></div>
          <div className="line3 bg-blue"></div>
        </div>
        <ul className="nav-links bg-white dark:bg-darkBlack">
          {props.theme === "dark" ? (
            <i
              className=" fa-regular fa-sun cursor-pointer hover:text-blue ease-in-out duration-300 text-lg "
              onClick={props.handleThemeSwitch}
            ></i>
          ) : (
            <i
              className="  fa-solid fa-moon cursor-pointer hover:text-blue ease-in-out duration-300 text-lg "
              onClick={props.handleThemeSwitch}
            ></i>
          )}
          {/* <li
						onClick={openNav}
						className="font-medium text-lg  hover:text-blue cursor-pointer ease-in-out duration-300 "
						>
						<HashLink smooth to="/#Courses">
						Courses
						</HashLink>
					</li> */}
          <li
            onClick={openNav}
            className="font-medium text-lg  hover:text-blue cursor-pointer ease-in-out duration-300 "
          >
            <HashLink smooth to="/#ourTeam">
              Our Team
            </HashLink>
          </li>

          {/* <li
						onClick={openNav}
						className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
					>

						<button
							style={{
								border: '1px dashed blue', // Adjust border properties as needed
								margin: '10px',
								padding: '8px 8px', // Optional: Adjust padding for better visual appearance
								// Add other styles such as background color, text color, etc., if needed
							}}
							onClick={go}

						>
							POTD
						</button>
					</li> */}

          <li
            onClick={openNav}

            className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
          >
            <NavLink to="/learn" onClick={notify}>Learn</NavLink>

          </li>
          <li
            onClick={openNav}

            className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
          >
            <NavLink to="/blog" onClick={notify2}>Blog</NavLink>

          </li>


          <li
            onClick={openNav}
            className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
          >
            <NavLink to="/OA_learn">OA</NavLink>
          </li>


          {userData && userData.isAdmin ? (

            <li
              onClick={openNav}
              className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
            >
              <NavLink to="/admin">Admin</NavLink>
            </li>
          ) : (
            ""
          )}

          {/* {userData && userData.isAdmin ? (

            <li
              onClick={openNav}
              className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
            >
              <NavLink to="/Blog-Admin">Blog Admin</NavLink>
            </li>
          ) : (
            ""
          )} */}
          {/* <li
              onClick={openNav}
              className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
            >
              <NavLink to="/Blog-Admin">Blog Admin</NavLink>
            </li> */}
          




          {/* <li
            onClick={openNav}
            className="font-medium text-md  hover:text-blue cursor-pointer ease-in-out duration-300 "
          >

            <NavLink to="/Upsolve/Platform">Upsolve</NavLink>
          </li> */}



          {userData && userData.length !== 0 ? (
            <>
              <NavLink
                onClick={openNav}
                to="/profile"
                className="flex items-center justify-center"
              >
                <h1 className="font-medium text-md text-blueGrey dark:text-blue2  hover:text-blue cursor-pointer ease-in-out duration-300 mr-3">
                  Hey,{" "}
                  <span className="font-medium text-md text-black dark:text-white  hover:text-blue cursor-pointer ease-in-out duration-300 ">
                    {userData.name}
                  </span>
                </h1>
                <img
                  className="rounded-full h-[40px]"
                  src={profile}
                  alt="profile" />
              </NavLink></>
          ) : (
            <NavLink
              onClick={openNav}
              className=" py-1.5 px-7 login-button text-white bg-blue hover:scale-105 ease-in-out duration-300 font-medium rounded"
              to='/login'
            >
              Login
            </NavLink>
          )}
        </ul>
      </nav>

    </>
  );
};

export default Navbar;
