import { useEffect, useRef, useState } from "react";
import "../../Styles/Charts/difficulty-chart.css"
import * as d3 from "d3";
import axios from "axios";
const DifficultyChart = ({ userData }) => {
    const questionsSolvedRef = useRef(null);
    const [questionsCount, setQuestionsCount] = useState({ total: 0, easy: 0, medium: 0, hard: 0 });
    const easyDone = questionsCount.easy == 0 ? 0 : (userData.easy_solved_count) * 100 / questionsCount.easy;
    const mediumDone = questionsCount.easy == 0 ? 0 : (userData.medium_solved_count) * 100 / questionsCount.medium;
    const hardDone = questionsCount.easy == 0 ? 0 : (userData.hard_solved_count) * 100 / questionsCount.hard;
    const totalDone = userData.easy_solved_count + userData.medium_solved_count + userData.hard_solved_count;

    const [svgDimensions, setSvgDimensions] = useState(
        {
            width: window.innerWidth > 768 ? window.innerWidth * 0.8 * 0.3 : window.innerWidth * 0.5,
            height: window.innerWidth > 768 ? window.innerWidth * 0.8 * 0.3 : window.innerWidth * 0.5,
        }
    );

    useEffect(() => {
        if (questionsCount.easy == 0) {
            getQuestionsCount();
            return;
        }
        const svg = d3.select(questionsSolvedRef.current);
        const radius = Math.min(svgDimensions.width * 0.8, svgDimensions.height * 0.8) / 2;
        const arc = d3.arc()
            .innerRadius(radius - 12.5 * radius / 100)
            .outerRadius(radius - 5 * radius / 100)
            .startAngle(0)
            .endAngle(2 * Math.PI)
            .cornerRadius(10)
        svg.append("g")
            .attr("transform", `translate(${svgDimensions.width / 2}, ${svgDimensions.height / 2})`)
            .append("path")
            .attr("d", arc)
            .attr("fill", "#d3d3d3")

        const colorArc = d3.arc()
            .innerRadius(radius - 15 * radius / 100)
            .outerRadius(radius)
            .startAngle(0)
            .endAngle(0)
            .cornerRadius(10)
        svg.append("g")
            .attr("transform", `translate(${svgDimensions.width / 2}, ${svgDimensions.height / 2})`)
            .append("path")
            .attr("d", colorArc)
            .attr("fill", "#00b894")
            .attr("class", "color-arc")



        svg.append("text")
            .attr("x", svgDimensions.width / 2)
            .attr("y", svgDimensions.height / 2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-size", radius * 0.3 + "px")
            .attr("font-weight", "bold")
            .attr("fill", "#00b894")
            .text(totalDone)

        svg.append("text")
            .attr("x", svgDimensions.width / 2)
            .attr("y", svgDimensions.height / 2 + radius * 0.3)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("font-size", radius * 0.15 + "px")
            .attr("fill", "#00b894")
            .text("Problems Solved")


        const difficultyContainer = document.querySelector("#difficulty-chart-container");
        difficultyContainer.addEventListener("transitionend", (e) => {
            if (e.target != difficultyContainer) return;
            if (!difficultyContainer.classList.contains("appear")) return;

            d3.select(".color-arc")
                .transition()
                .duration(2000)
                .ease(d3.easeLinear)
                .attrTween("d", function () {
                    return function (t) {
                        colorArc.endAngle(2 * Math.PI * totalDone / questionsCount.total * t)
                        return colorArc()
                    }
                })

            document.querySelector(".easy-chart .upper-bar").style.width = `${easyDone}%`
            document.querySelector(".medium-chart .upper-bar").style.width = `${mediumDone}%`
            document.querySelector(".hard-chart .upper-bar").style.width = `${hardDone}%`
        })


    }, [questionsCount])

    async function getQuestionsCount() {
        await axios.get("/getQuestionsCount").then(res => {
            setQuestionsCount(res.data);
        })
    }
    return (
        <div id="difficulty-chart-container">
            <h1>Problems by Difficulty</h1>
            <div className="difficulty-charts-parent">
                <svg ref={questionsSolvedRef} width={svgDimensions.width} height={svgDimensions.height}></svg>
                <div className="difficulty-bars-parent">
                    <div className="easy-chart difficulty-chart">
                        <div className="progress-bar">
                            <div className="lower-bar difficulty-bar"></div>
                            <div className="upper-bar difficulty-bar" ></div>
                        </div>
                        <div className="difficulty-bar-text">
                            <p>{Math.round(easyDone)}%</p>
                            <p>{Math.round(easyDone / 100 * questionsCount.easy)}/{questionsCount.easy}</p>
                        </div>
                    </div>
                    <div className="medium-chart difficulty-chart">
                        <div className="progress-bar">
                            <div className="lower-bar difficulty-bar"></div>
                            <div className="upper-bar difficulty-bar" ></div>
                        </div>
                        <div className="difficulty-bar-text">
                            <p>{Math.round(mediumDone)}%</p>
                            <p>{Math.round(mediumDone / 100 * questionsCount.medium)}/{questionsCount.medium}</p>
                        </div>
                    </div>
                    <div className="hard-chart difficulty-chart">
                        <div className="progress-bar">
                            <div className="lower-bar difficulty-bar"></div>
                            <div className="upper-bar difficulty-bar" ></div>
                        </div>
                        <div className="difficulty-bar-text">
                            <p>{Math.round(hardDone)}%</p>
                            <p>{Math.round(hardDone / 100 * questionsCount.hard)}/{questionsCount.hard}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DifficultyChart