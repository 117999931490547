import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ashish from "../../../assets/Senior.png";
import 'react-quill/dist/quill.snow.css';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Loader from "../../../components/Loader";

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (id) {
      const fetchBlog = async () => {
        try {
          const response = await axios.get(`/api/blogs?id=${id}`);
          setBlog(response.data);
        } catch (err) {
          console.error("Error fetching blog details:", err);
          setError("Failed to load blog details.");
        } finally {
          setLoading(false);
        }
      };

      fetchBlog();
    }
  }, [id]);

  if (loading) return <div className="text-center py-10"><Loader /></div>;
  if (error) return <div className="text-center text-red-500 py-10">{error}</div>;

  return (
    <div className="mx-auto bg-gradient-to-r dark:from-black dark:via-black dark:to-gray-900 from-gray-100 via-gray-100 to-gray-100 min-h-screen transition-colors duration-300 p-8" 
    >
     
      {blog && (
         
        <div className="container mx-auto px-4 flex flex-col md:flex-row">
          <div className="w-full md:w-3/4 px-4">
            <article className="prose max-w-none text-white">
              <h1 className="text-4xl font-bold text-gray-700 dark:text-gray-300 mb-4">{blog.title}</h1>
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                Published on {new Date(blog.createdAt).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
              </p>
              {blog.image && (
                <img src={blog.image} alt="Blog Featured" className="mb-8 rounded-lg shadow-lg"/>
              )}

<div className="p-5 rounded-2xl bg-white dark:bg-gray-900">
  <div className="border-b pb-4">
    <h1 className="mb-6 text-gray-700 dark:text-gray-300 text-3xl font-bold">{blog.description}</h1>
  </div>


  <div 
    className="text-gray-700 dark:text-gray-300 mb-4"
    dangerouslySetInnerHTML={{ __html: blog.content }}
  />

  {blog.exampleCode && (
    <h2 className="text-2xl font-bold mb-4">{blog.exampleCode}</h2>
  )}

<div className="overflow-x-auto  p-4 rounded-lg shadow-md">
      <pre className="text-sm leading-relaxed rounded-xl bg-black p-5 text-white">
        <code className="language-javascript">
          {blog.code}
        </code>
      </pre>
    </div>
</div>
            </article>
          </div>
          <aside className="w-full md:w-1/4 px-4 mt-8 md:mt-0">
            <div className="p-4 border rounded-lg dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
              <div className="flex items-center mb-4">
                <img src={ashish} alt={blog.author} className="w-12 h-12 rounded-full mr-4"/>
                <div>
                  <p className="font-semibold text-gray-700 dark:text-gray-300">{blog.author}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Editor</p>
                </div>
              </div>
              <button className="w-full px-4 py-2 text-white bg-green-700 rounded-lg hover:bg-green-800 transition">Follow</button>
            </div>
            <div className="mt-6 p-4 dark:text-gray-200">
              <h2 className="text-xl font-bold mb-4">Categories</h2>
              <ul className="list-disc list-inside ">
                <li className="mb-2"><a href="#" className="hover:underline">Category 0</a></li>
                <li className="mb-2"><a href="#" className="hover:underline">Category 1</a></li>
                <li className="mb-2"><a href="#" className="hover:underline">Category 2</a></li>
                <li className="mb-2"><a href="#" className="hover:underline">Category 3</a></li>
              </ul>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
};

export default BlogDetails;
