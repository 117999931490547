/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar";
import { allProgress } from "../../redux/slices/AllProgressSlice";
import { tagsProgress } from "../../redux/slices/TagsProgressSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LearnCard from "../../components/LearnCard";
import axios from "axios";
import Loader from "../../components/Loader";

const Learn = () => {
	const [visitor_count, set_visitor_count] = useState(null);
	const update_count = async () => {
		if (!visitor_count) {
			await axios.post("/updateCount", {}).then((res) => {
				set_visitor_count(res.data.count);
			});
		}
	}
	update_count();
	const dispatch = useDispatch();

	//Fetching current user details
	const userID = useSelector((state) => state.user.user._id);

	//! <-------- Fetch Tags from DB ---------->
	const [LearnCardData, setLearnCardData] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
			  if (userID) {
				await dispatch(allProgress(userID));
				await dispatch(tagsProgress(userID));
		
				// Fetching all Tags progress percent from localStorage
				const allTagsPercent = window.localStorage.getItem("ALL_TAG_PERCENT");
		
				if (allTagsPercent) {
				  // Parse the JSON string into a JavaScript object
				  const parsedObject = JSON.parse(allTagsPercent);
		
				  // Convert the object properties into key-value pairs
				  const keyValuePairs = Object.entries(parsedObject);
		
				  setLearnCardData(keyValuePairs);
				}
			  } else {
				const res = await axios.get("/tags");
		
				// Convert the array into a key-value pair object
				const keyValueObject = {};
				res.data.title.forEach((element, index) => {
				  keyValueObject[element] = index + 1;
				});
		
				// Convert the object into an array of key-value pairs
				const keyValueArray = Object.entries(keyValueObject);
				setLearnCardData(keyValueArray);
			  }
			} catch (e) {
			  console.error(e);
			}
		  };
		
		  fetchData();
		}, [userID]);

	const MainProgressBarCSS = {
		bgLine: "bg-blue px-1 h-5 dark:bg-darkBlue",
		frontLine: "bg-darkBlue h-3 dark:bg-blue2",
		text: "text-darkBlue  text-2xl font-semibold mt-2 dark:text-blue2",
	};

	//! <------------ Search bar Logic ----------->
	// const [data, setData] = useState(LearnCardData);
	// const [searchTerm, setSearchTerm] = useState('');

	//   const handleSearch = (event) => {
	//     const term = event.target.value.toLowerCase();
	//     setSearchTerm(term);
	//     const filteredData = LearnCardData.filter((item) =>
	//       item[0].toLowerCase().includes(term)
	//     );
	//     setData(filteredData);
	//   };

	//   useState(()=>{
	// 	console.log(data);
	//   },[data])

	return (
		<>
			{LearnCardData ? (
				<>
					<section className=" bg-blue2 px-[5%] md:px-[10%] flex flex-col items-center justify-center dark:bg-dark ">
						<div className="pt-[110px] text-center lg:pt-[120px]">
							<h1 className="text-blue font-bold text-4xl md:text-5xl dark:text-white ">
								Road to Candidate Master
							</h1>
							{userID ? (
								<p className="text-darkBlue font-semibold text-lg mt-5 mb-3 dark:text-blue2">
									Overall Progress
								</p>
							) : null}
						</div>
						{userID ? (
							<div className="w-[90%]">
								<ProgressBar
									progressBarCSS={MainProgressBarCSS}
									width={window.localStorage.getItem("ALL_PERCENT")}
								/>
							</div>
						) : null}
						<div className="mt-[50px] flex items-center gap-5 justify-center">
							<p className="font-semibold lg:text-xl text-md text-dark dark:text-white">
								How to use this sheet?
							</p>
							<a
								href="https://docs.google.com/document/d/1IZbxkKRb3ppBRiEgfcfuUEb4mv6kleqLTSgZqGW4X48/edit?usp=sharing"
								target="_blank"
								rel="noreferrer"
								className="font-medium border-2 border-blue6 rounded-lg py-1 px-2 text-sm lg:text-lg  lg:py-2 lg:px-6 bg-blue6 text-white hover:text-white hover:border-black hover:bg-black ease-in-out duration-300 dark:bg-blue dark:border-blue dark:hover:bg-dark dark:hover:text-blue">
								Know more
							</a>
						</div>
					</section>
					{/* <---------------------- Search bar --------------------> */}
					{/* <form className="px-[20%] bg-blue2 dark:bg-dark">
						<label
							htmlFor="default-search"
							className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
						>
							Search
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
								<svg
									className="w-4 h-4 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 20"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
									/>
								</svg>
							</div>
							<input
							value={searchTerm}
        					onChange={handleSearch}
								type="search"
								id="default-search"
								className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								placeholder="Search any topic..."
							></input>
							<button
								// onClick={handleSearch}
								className="text-white absolute right-2.5 bottom-2.5 bg-blue hover:bg-darkBlue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
							>
								Search
							</button>
						</div>
					</form> */}
					{/* <---------------------- Cards --------------------> */}
					<section className=" bg-blue2 px-[5%] md:px-[10%] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-14 md:gap-10 gap-5 py-10 lg:py-[50px] dark:bg-dark">
						{LearnCardData.map(([key, solvedData]) => {
							return <LearnCard tagName={key} percentSolved={solvedData.percentage} UserID={userID} key={key} totalQuestions={solvedData.total} solvedQuestions={solvedData.solved} />;
						})}
					</section>
				</>
			) : (
				<div className="flex items-center justify-center bg-blue2 h-screen w-screen dark:bg-dark">
					<Loader
					/>
				</div>
			)}
		</>
	);
};

export default Learn;
